import type { GridSize } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import { RichTextGlobal } from '../RichTextGlobal';
import type { TileProps } from './Tile.types';

const PREFIX = 'Tile';

const classes = {
  background: `${PREFIX}-background`,
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.background}`]: {
    boxShadow: '0px 6px 20px rgba(3, 28, 106, 0.161)',
    boxSizing: 'border-box',
    transition: 'transform .2s',
    minHeight: '300px',
    height: '100%',
    padding: '24px 20px',
    '&:hover': {
      transform: 'scale(1.05)',
    },

    '& *': {
      marginBottom: '1.2rem',
    },
  },
}));

export const Tile: React.FC<TileProps> = ({ tile }) => {
  return (
    <StyledGrid
      item
      lg={tile.fields.lg as GridSize}
      md={tile.fields.md as GridSize}
      sm={tile.fields.sm as GridSize}
      xs={tile.fields.sm as GridSize}>
      <div className={classes.background}>
        <RichTextGlobal content={tile.fields.content} />
      </div>
    </StyledGrid>
  );
};
