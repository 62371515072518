import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import type { GridSize } from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import Image from '../Image';
import type { IconCardProps } from './IconCard.types';

const PREFIX = 'IconCard';

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  description: `${PREFIX}-description`,
};

const StyledGrid = styled(Grid)<IconCardProps>(({ theme, card }) => ({
  paddingTop: 10,
  paddingBottom: 10,

  [`& .${classes.item}`]: {
    height: '100%',
    width: card.fields.image?.fields.width,
  },

  [`& .${classes.description}`]: {
    top: '50%',
    transform: 'translateY(15%)',
    paddingLeft: 10,
    [theme.breakpoints.down('lg')]: {
      paddingLeft: 0,
      paddingTop: 20,
    },
  },
}));

export const IconCard: React.FC<IconCardProps> = ({ card }) => {
  return (
    <StyledGrid
      card={card}
      item
      xs={card.fields.xs as GridSize}
      sm={card.fields.sm as GridSize}
      md={card.fields.md as GridSize}
      lg={card.fields.lg as GridSize}>
      <Grid container className={classes.root}>
        <Grid xs={12} sm={12} md={12} lg={2} className={classes.item} item>
          {card.fields.image && <Image model={card.fields.image} />}
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={8}
          className={classes.description}
          item>
          <Typography variant="h3">{card.fields.title}</Typography>
          <Typography>{card.fields.description}</Typography>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};
