/* eslint-disable */
import React from 'react';
export const __dev__ = process && process.env.NODE_ENV === 'development';

type OnProdProps = {
  prodSiteUrl: string;
};

export const OnProd: React.FC<OnProdProps> = ({ children, prodSiteUrl }) =>
  typeof window !== 'undefined' &&
  window.location.hostname !== prodSiteUrl ? null : (
    <>{children}</>
  );
