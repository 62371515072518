import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import TypoGraphy from '@mui/material/Typography';
import React from 'react';
import quote from '../../../../../src/assets/png/quote.png';
import type { CaseStudyTestimonialProps } from './CaseStudyTestimonial.types';

const PREFIX = 'CaseStudyTestimonial';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  contentContainer: `${PREFIX}-contentContainer`,
  content: `${PREFIX}-content`,
  authorName: `${PREFIX}-authorName`,
  authorPosition: `${PREFIX}-authorPosition`,
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.root}`]: {},

  [`& .${classes.image}`]: {
    width: '100%',
  },

  [`& .${classes.contentContainer}`]: {
    backgroundImage: `url(${quote})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '40px 40px',
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },

  [`& .${classes.content}`]: {
    fontSize: '24px',
    lineHeight: '32px',
    paddingTop: '48px',
    paddingRight: '0 !important',
    paddingLeft: '0 !important',
    flex: 1,
  },

  [`& .${classes.authorName}`]: {
    marginTop: '24px',
    marginBottom: '0 !important',
    flex: 0,
    '&:after': {
      display: 'none',
    },
  },

  [`& .${classes.authorPosition}`]: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '24px',
    flex: 0,
  },
}));

export const CaseStudyTestimonial: React.FC<CaseStudyTestimonialProps> = ({
  model,
}) => {
  return (
    <StyledGrid container className={classes.root}>
      <Grid item xs={false} md={1} lg={2} />
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <img
          className={classes.image}
          src={model.fields.author.fields.avatar?.fields.file.url}
          alt={
            model.fields.author.fields.avatar?.fields.description ||
            'author image'
          }
        />
      </Grid>
      <Grid
        className={classes.contentContainer}
        item
        xs={12}
        sm={7}
        md={6}
        lg={6}>
        <TypoGraphy className={classes.content}>
          {model.fields.content}
        </TypoGraphy>
        <TypoGraphy variant="h4" className={classes.authorName}>
          {model.fields.author.fields.name}
        </TypoGraphy>
        <TypoGraphy variant="h6" className={classes.authorPosition}>
          {model.fields.author.fields.position}
        </TypoGraphy>
      </Grid>
    </StyledGrid>
  );
};
