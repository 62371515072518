import type { GridSize } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React from 'react';
import dot from '../../assets/png/dot-bg.png';
import { RichTextGlobal } from '../RichText/global';
import type { BlockProps } from './Block.types';

const PREFIX = 'Block';

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}Item`,
  backgroundColor: `${PREFIX}BackgroundColor`,
};

const StyledGrid = styled(Grid)<BlockProps>(({ theme, block }) => ({
  [`&.${classes.root}`]: {
    '& ul': {
      padding: 0,
    },
    '& li': {
      padding: theme.spacing(1),
      '&:before': {
        width: 16,
        height: 16,
        top: '50%',
        transform: 'translateY(15%)',
        background: `url(${dot})`,
      },
    },
  },

  [`&.${classes.item}`]: () => {
    const backgroundImage = block.fields.backgroundImage;
    if (!backgroundImage) {
      return {
        zIndex: 2,
        [theme.breakpoints.down('lg')]: {
          textAlign: 'center',
        },
      };
    }
    return {
      position: 'relative',
      zIndex: 1,
      [theme.breakpoints.up('md')]: {
        '&:after': {
          content: "''",
          position: 'absolute',
          width: '100vw',
          height: `${backgroundImage.fields.height}%`,
          background: `url(${backgroundImage.fields.image?.fields.file.url})`,
          backgroundPositionX: backgroundImage.fields.align,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'auto 100%',
          backgroundPositionY: '0',
          [backgroundImage.fields.align]: `${backgroundImage.fields.left}%`,
          top: `${backgroundImage.fields.top}${backgroundImage.fields.topUnit}`,
          zIndex: -1,
        },
        '& > div': {
          position: 'relative',
          zIndex: 1,
        },
      },
      [theme.breakpoints.down('lg')]: {
        textAlign: 'center',
      },
    };
  },

  [`&.${classes.backgroundColor}`]: () => {
    if (!block.fields.backgroundColor) return {};
    return {
      paddingBottom: '0 !important',
      marginBottom: '40px',
      backgroundColor: block.fields.backgroundColor,
      borderRadius: block.fields.backgroundRadius,
    };
  },
}));

export const Block: React.FC<BlockProps> = ({ block }) => {
  return (
    <StyledGrid
      block={block}
      item
      md={block.fields.md as GridSize}
      sm={block.fields.sm as GridSize}
      xs={block.fields.xs as GridSize}
      className={clsx(classes.backgroundColor, classes.item, classes.root)}>
      <RichTextGlobal content={block.fields.content} />
    </StyledGrid>
  );
};
