import type { EmotionCache } from '@emotion/react';
import { CacheProvider } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { MarketingScripts, theme } from '@rumblefish/ui';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import createEmotionCache from '../utils/createEmotionCache';
import { marketingScriptsData } from '../utils/javaMarketingScriptsData';

const clientSideEmotionCache = createEmotionCache();

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  return (
    <>
      <MarketingScripts {...marketingScriptsData} />
      <CacheProvider value={emotionCache}>
        <Head>
          <title>Rumblefish - JAVA Software Development</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width"
          />
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...pageProps} />
        </ThemeProvider>
      </CacheProvider>
    </>
  );
}
