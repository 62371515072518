import type { ImageLoaderProps } from 'next/image';
import NextImage from 'next/image';
import React from 'react';
import type { ContentfulImageProps } from './ContentfulImage.types';

function contentfulImageLoader({
  src,
  width,
  quality,
}: ImageLoaderProps): string {
  return `${src}?w=${width}&q=${quality || 75}&fit=fill&f=face`;
}

export const ContentfulImage: React.FC<ContentfulImageProps> = ({
  image: { fields },
  width,
  height,
}) => {
  return (
    <NextImage
      loader={contentfulImageLoader}
      src={fields.file.url}
      alt={fields.description}
      width={width}
      height={height}
    />
  );
};
