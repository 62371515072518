import type { Theme } from '@mui/material';
import { Grid, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';
import React from 'react';
import home from '../../assets/svg/icons/home.svg';
import { colors } from '../../theme/default/color';
import type { NavigatorProps } from './Navigator.types';

const PREFIX = 'Navigator';

const classes = {
  root: `${PREFIX}-root`,
  homeIcon: `${PREFIX}-homeIcon`,
  backSlesh: `${PREFIX}-backSlesh`,
  home: `${PREFIX}-home`,
  container: `${PREFIX}-container`,
  NavLink: `${PREFIX}-NavLink`,
  blueColor: `${PREFIX}-blueColor`,
  sub: `${PREFIX}-sub`,
};

const NavigatorContainer = styled('div')(({ theme }: { theme: Theme }) => ({
  color: theme.palette.primary.main,
  [theme.breakpoints.down('md')]: {
    top: '80px',
  },

  [`& .${classes.homeIcon}`]: {
    marginRight: '20px',
    display: 'inline',
  },

  [`& .${classes.backSlesh}`]: {
    display: 'inline',
    margin: '0 0.3rem',
  },

  [`& .${classes.home}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '1rem',
    fontWeight: 'bold',
    color: colors.black,
    textTransform: 'uppercase',
    fontSize: '13px',
  },

  [`& .${classes.container}`]: {
    backgroundColor: colors.lightgray,
    height: '115px',
    padding: '10px',
    position: 'relative',
    zIndex: 10,
    [theme.breakpoints.only('xs')]: {
      display: 'none',
    },
  },

  [`& .${classes.NavLink}`]: {
    textDecoration: 'none',
    color: colors.black,
  },

  [`& .${classes.blueColor}`]: {
    color: colors.blue,
  },

  [`& .${classes.sub}`]: {
    color: colors.blue,
    [theme.breakpoints.up('md')]: {
      whiteSpace: 'nowrap',
    },
  },
}));

const OldNavigator: React.FC<NavigatorProps> = ({
  name,
  subname,
  backSlesh,
  path,
  noBackground,
}) => (
  <NavigatorContainer>
    <Grid
      className={classes.container}
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={
        noBackground
          ? { backgroundColor: 'transparent' }
          : { backgroundColor: colors.lightgray }
      }>
      <Grid
        item
        xs={12}
        sm={10}
        lg={10}
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="start">
        <Link passHref aria-label="home" href="/">
          <img
            className={classes.homeIcon}
            src={home}
            alt="home page icon"
            width="16"
            height="16"
          />
        </Link>
        <Typography component="div" className={classes.home} variant="h6">
          <Link passHref href="/">
            <a className={classes.NavLink}>HOME</a>
          </Link>
          <Typography
            component="div"
            className={classes.backSlesh}
            color="secondary">
            /
          </Typography>
          <Link passHref href={`/${path}/`}>
            <a className={classes.NavLink}>{name}</a>
          </Link>
          <Typography
            component="div"
            className={classes.backSlesh}
            color="secondary">
            {backSlesh}
          </Typography>
          <span className={classes.sub}>{subname}</span>
        </Typography>
      </Grid>
    </Grid>
  </NavigatorContainer>
);

const StyledLink = styled('a')(({ theme }) => ({
  fontWeight: '600',
  textDecoration: 'none',
  color: theme.palette.grey[400],
}));

const NewNavigator: React.FC<NavigatorProps> = ({
  name,
  subname,
  backSlesh = '/',
  path,
}) => (
  <Box
    display="flex"
    gap={2}
    sx={{
      textTransform: 'uppercase',
      fontWeight: '600',
    }}>
    <Link passHref href="/">
      <StyledLink>home</StyledLink>
    </Link>
    <Typography component="div" color="secondary">
      {backSlesh}
    </Typography>
    <Link passHref href={`/${path}/`}>
      <StyledLink>{name}</StyledLink>
    </Link>
    {subname && (
      <>
        <Typography component="div" color="secondary">
          {backSlesh}
        </Typography>
        <Typography component="div" color={colors.blue} fontWeight="600">
          <span>{subname}</span>
        </Typography>
      </>
    )}
  </Box>
);

export const Navigator: React.FC<NavigatorProps> = ({
  newDesign = false,
  ...navProps
}) => {
  return newDesign ? (
    <NewNavigator {...navProps} />
  ) : (
    <OldNavigator {...navProps} />
  );
};
