import { styled } from '@mui/material/styles';
import {
  GoogleApiWrapper as withGoogleApi,
  Map as GoogleMap,
  Marker,
} from 'google-maps-react';
import React from 'react';
import pointer from '../../assets/svg/icons/pointerMap.svg';
import { colors } from '../../theme/default/color';
import { config } from './config';
import customGoogleMapsStyles from './customGoogleMapsStyles';
import type { MapProps } from './Map.types';

const PREFIX = 'Map';

const classes = {
  root: `${PREFIX}-root`,
  map: `${PREFIX}-map`,
};

const Root = styled('div')(() => ({
  [`& .${classes.map}`]: {
    border: 'solid 2px',
    borderColor: colors.lightgray100,
  },
}));

const MapLoadingComponent: React.FC = () => {
  return <Root className={classes.root} />;
};

const MapComponent: React.FC<MapProps> = ({ google }) => {
  return (
    <Root>
      <GoogleMap
        google={google}
        styles={customGoogleMapsStyles}
        initialCenter={{ lat: 50.0696058, lng: 19.967119 }}
        zoom={16}>
        <Marker icon={{ url: pointer }} title="Current location" />
      </GoogleMap>
    </Root>
  );
};

export const Map = withGoogleApi({
  apiKey: config.GOOGLE_MAPS_API_KEY,
  LoadingContainer: MapLoadingComponent,
})(MapComponent);
