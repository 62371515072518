import { Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import type { IQuestionAndAnswerFields } from '@rumblefish/java/utils/contentful.types';
import clsx from 'clsx';
import React, { useState } from 'react';
import faqClose from '../../assets/png/faqClose.png';
import faqOpen from '../../assets/png/faqOpen.png';
import { Divider } from '../Divider';

const PREFIX = 'FaqIssue';

const classes = {
  faqWrapper: `${PREFIX}-faqWrapper`,
  faqQuestionWrapper: `${PREFIX}-faqQuestionWrapper`,
  closeIcon: `${PREFIX}-closeIcon`,
  openIcon: `${PREFIX}-openIcon`,
  closeAnswer: `${PREFIX}-closeAnswer`,
  openAnswer: `${PREFIX}-openAnswer`,
};

const Root = styled('div')(
  ({
    theme: {
      spacing,
      template: { transitionOptions },
    },
  }: {
    theme: Theme;
  }) => ({
    [`&.${classes.faqWrapper}`]: {
      margin: spacing(3, 0),
      display: 'grid',
      gap: spacing(2),
    },

    [`& .${classes.faqQuestionWrapper}`]: {
      textAlign: 'left',
      border: 'none',
      background: 'transparent',
      transition: transitionOptions,
      padding: '0.4rem',

      '&:focus': {
        borderRadius: '0.4rem',
      },

      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },

    [`& .${classes.closeIcon}`]: {
      marginRight: spacing(2),
      flex: `0 0 ${faqIssueIconSize}`,
      width: faqIssueIconSize,
      height: faqIssueIconSize,
      objectFit: 'cover',
      backgroundImage: `url(${faqOpen})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      transition: transitionOptions,
    },

    [`& .${classes.openIcon}`]: {
      backgroundImage: `url(${faqClose})`,
    },

    [`& .${classes.closeAnswer}`]: {
      overflow: 'hidden',
      cursor: 'pointer',
      display: 'inline-block',
      maxHeight: 0,
      transition: transitionOptions,
    },

    [`& .${classes.openAnswer}`]: {
      maxHeight: '100%',
    },
  })
);

const faqIssueIconSize = '1.375rem';

export const FaqIssue: React.FC<IQuestionAndAnswerFields> = ({
  question,
  answer,
}) => {
  const [isIssueOpen, setIsIssueOpen] = useState(false);
  const handleTriggerIssue = () => setIsIssueOpen(!isIssueOpen);
  const handleCloseIssue = () => setIsIssueOpen(false);

  return (
    <Root className={classes.faqWrapper}>
      <button
        className={classes.faqQuestionWrapper}
        onClick={handleTriggerIssue}>
        <div
          className={clsx(classes.closeIcon, {
            [classes.openIcon]: isIssueOpen,
          })}
        />
        <Typography variant="h4" component="h3">
          {question}
        </Typography>
      </button>
      <Typography
        className={clsx(classes.closeAnswer, {
          [classes.openAnswer]: isIssueOpen,
        })}
        variant="body2"
        onClick={handleCloseIssue}>
        {answer}
      </Typography>
      <Divider />
    </Root>
  );
};
