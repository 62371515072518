import type { Theme } from '@mui/material';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import Image from '../Image';
import type { PersonCardProps } from './PersonCard.types';

const PREFIX = 'PersonCard';

const classes = {
  image: `${PREFIX}-image`,
};

const StyledGrid = styled(Grid)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.image}`]: {
    padding: `${theme.spacing(2)} ${theme.spacing(2)} 0px 0px`,
    backgroundPosition: 'center',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(5),
    },
  },
}));

export const PersonCard: React.FC<PersonCardProps> = ({ model }) => {
  return (
    <StyledGrid container alignItems="center">
      <Grid item xs={12} md={12} lg={4}>
        <div className={classes.image}>
          <Image model={model.fields.picture} />
        </div>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <Typography variant="h5">{model.fields.description}</Typography>
        <br />
        <Typography style={{ color: '#365AEA' }}>
          {model.fields.email}
        </Typography>
      </Grid>
    </StyledGrid>
  );
};
