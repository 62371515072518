import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import type { ButtonProps } from './ActionButton.types';
const PREFIX = 'ActionButton';

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
};

const StyledGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop === 'model',
})<ButtonProps>(({ theme, model }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.down('lg')]: {
      display: 'block',
      textAlign: 'center',
    },
  },

  [`& .${classes.button}`]: { marginTop: theme.spacing(model.fields.top || 1) },
}));

export const ActionButton: React.FC<ButtonProps> = ({ model }) => {
  const scrollHandler = () => {
    const anchor = document.getElementById(model.fields?.anchorId || '');
    anchor && anchor.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <StyledGrid model={model} container className={classes.root}>
      <Grid item>
        <Button
          onClick={scrollHandler}
          color="primary"
          variant="contained"
          className={classes.button}>
          {model.fields.label}
        </Button>
      </Grid>
    </StyledGrid>
  );
};
