import Box from '@mui/material/Box';
import React from 'react';
import { Person } from '../Person';
import { SpeechBubbleGradient } from '../SpeechBubble';
import type { TestimonialMainProps } from './TestimonialMain.types';

export const TestimonialMain: React.FC<TestimonialMainProps> = ({
  name,
  role,
  content,
  image,
}) => {
  return (
    <Box my={4}>
      <SpeechBubbleGradient content={content} />
      <Person name={name} position={role} image={image} />
    </Box>
  );
};
