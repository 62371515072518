import Grid from '@mui/material/Grid';
import React from 'react';
import { IconCard } from '../IconCard';
import type { IconCardsCollectionProps } from './IconCardsCollection.types';

export const IconCardsCollection: React.FC<IconCardsCollectionProps> = ({
  model,
}) => {
  return (
    <Grid mb={4} container spacing={5}>
      {model?.fields.items?.map((card) => (
        <IconCard card={card} key={card.sys.id} />
      ))}
    </Grid>
  );
};
