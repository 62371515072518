import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../theme/default/color';
import { ContactForm } from '../ContactForm';
import { GradientText } from '../GradientText';
import type { LetsCollaborateProps } from './LetsCollaborate.types';

const StyledLetsCollaborate = styled('section')({
  background: colors.white,
  padding: '128px 0',
});

export const LetsCollaborate: React.FC<LetsCollaborateProps> = ({
  github = false,
  linkedin = false,
  subject,
  submitButtonText,
  scrollRef,
}) => {
  return (
    <StyledLetsCollaborate>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={10} sm={8} container justifyContent="center">
          <Grid item xs={12} md={6} ref={scrollRef}>
            <GradientText type="huge">
              let's
              <br />
              collab
              <br />
              orate
            </GradientText>
          </Grid>
          <Grid item xs={12} md={6}>
            <ContactForm
              subject={subject}
              submitButtonText={submitButtonText}
              github={github}
              linkedin={linkedin}
            />
          </Grid>
        </Grid>
      </Grid>
    </StyledLetsCollaborate>
  );
};
