import type { AlertProps } from '@mui/material/Alert';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../theme/default/color';
import type { AlertSnackbarProps } from './AlertSnackbar.types';

const Alert: React.FC<AlertProps> = React.forwardRef(function Forward(
  props,
  ref
) {
  return <MuiAlert ref={ref} elevation={6} variant="filled" {...props} />;
});

const StyledAlert = styled(Alert)({
  background: colors.lightblue,
});

export const AlertSnackbar: React.FC<AlertSnackbarProps> = ({
  openSuccessMessage,
  setOpenSuccessMessage,
  openErrorMessage,
  setOpenErrorMessage,
  succesMessage,
  errorMessage,
}) => {
  return (
    <>
      {succesMessage && (
        <Snackbar
          open={openSuccessMessage}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={() => setOpenSuccessMessage && setOpenSuccessMessage(false)}>
          <StyledAlert
            onClose={() =>
              setOpenSuccessMessage && setOpenSuccessMessage(false)
            }
            severity="success">
            {succesMessage}
          </StyledAlert>
        </Snackbar>
      )}
      {errorMessage && (
        <Snackbar
          open={openErrorMessage}
          autoHideDuration={6000}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          onClose={() => setOpenErrorMessage && setOpenErrorMessage(false)}>
          <Alert
            onClose={() => setOpenErrorMessage && setOpenErrorMessage(false)}
            severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};
