import Script from 'next/script';
import React from 'react';
import type { IGoogleTagManager } from './MarketingScripts.types';

declare global {
  interface Window {
    dataLayer: Record<string, any>;
  }
}

export const gTagPageview = (url: URL) => {
  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
};

export const GoogleTagManager = ({ gtmId }: IGoogleTagManager) => {
  return (
    <Script
      id="gtm"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${gtmId}');
          `,
      }}
    />
  );
};
