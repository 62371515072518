import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../theme/default/color';
import type { DividerProps } from './Divider.types';

const PREFIX = 'Divider';

const classes = {
  divider: `${PREFIX}-divider`,
};

const Root = styled('div')<DividerProps>(({ type }) => ({
  [`&.${classes.divider}`]: {
    height: '1px',
    background: colors.dividerGray,
    opacity: type === 'lighten' ? 0.15 : 1,
  },
}));

export const Divider: React.FC<DividerProps> = ({ type = 'normal' }) => {
  return <Root type={type} className={classes.divider} />;
};
