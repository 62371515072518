import type { Palette, PaletteColor } from '@mui/material';

declare module '@mui/material/styles/createPalette' {
  interface BrandPaletteColorOptions {
    primary: string;
    secondary: string;
  }
  interface BrandPaletteColor {
    primary: string;
    secondary: string;
  }
  interface BackgroundPaletteColorOptions {
    dark: string;
    light: string;
  }
  interface BackgroundPaletteColor {
    dark: string;
    light: string;
    darkPurple: string;
  }
  interface GreyPaletteColorOptions {
    primary: string;
    light: string;
    shade00: string;
    shade10: string;
    shade20: string;
    shade30: string;
    shade50: string;
    shade60: string;
    shade70: string;
  }
  interface GreyPaletteColor {
    primary: string;
    light: string;
    lightSecondary: string;
    shade00: string;
    shade10: string;
    shade20: string;
    shade30: string;
    shade50: string;
    shade60: string;
    shade70: string;
    disabledDark: string;
  }

  interface PaletteOptions {
    colorBrand?: BrandPaletteColorOptions;
    colorLink?: string;
    colorBlack?: string;
    colorBackground?: BackgroundPaletteColorOptions;
    colorLines?: string;
    colorLines50?: string;
    colorGrey?: GreyPaletteColorOptions;
    colorWhite?: string;
  }
  interface Palette {
    type: string;
    primaryTextButtonColor: string;
    primaryButtonHoverBgColor: string;
    colorBrand?: BrandPaletteColor;
    colorLink?: string;
    colorBlack?: string;
    colorBackground?: BackgroundPaletteColor;
    colorLines?: string;
    colorLines50?: string;
    colorGrey?: GreyPaletteColor;
    colorWhite?: string;
  }
}

export const palette: Palette = {
  type: 'light',
  colorBrand: {
    primary: '#01196F',
    secondary: '#F9277F',
  },
  primaryTextButtonColor: '#FFFFFF',
  primaryButtonHoverBgColor: '#01196F',
  colorLink: '#2F57F4',
  colorBlack: '#070706',
  colorBackground: {
    dark: '#F2F1F2',
    light: '#F8F8F7',
    darkPurple: '#171019',
  },
  colorLines: '#C8C8C8',
  colorLines50: '#EDEDED',
  colorWhite: '#FFFFFF',
  colorGrey: {
    primary: '#494949',
    light: '#747474',
    lightSecondary: '#F5F6F8',
    shade00: '#F2F4F8',
    shade10: '#DDE1E6',
    shade20: '#C1C7CD',
    shade30: '#A2A9B0',
    shade50: '#697077',
    shade60: '#4D5358',
    shade70: '#343A3F',
    disabledDark: '#697077',
  },

  // Used in RichTextGlobal and RichTextLandingPages
  // So when storybook is using algeaTheme, this line is needed in order for it to work
  // Otherwise storybook won't load stories

  secondary: {
    main: '#C1C7CD',
  } as PaletteColor,
} as Palette;

export const paletteDark: Palette = {
  type: 'dark',
  colorBrand: {
    primary: '#01196F',
    secondary: '#F9277F',
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#ABB1B4',
    disabled: '#494949',
  },
  primaryTextButtonColor: '#070706',
  primaryButtonHoverBgColor: 'none',
  colorLink: '#FFFFFF',
  colorBlack: '#070706',
  colorBackground: {
    dark: '#F2F1F2',
    light: '#F8F8F7',
    darkPurple: '#171019',
  },
  colorLines: '#C8C8C8',
  colorLines50: '#EDEDED',
  colorWhite: '#FFFFFF',
  colorGrey: {
    primary: '#494949',
    light: '#747474',
    lightSecondary: '#F5F6F8',
    shade00: '#F2F4F8',
    shade10: '#DDE1E6',
    shade20: '#C1C7CD',
    shade30: '#A2A9B0',
    shade50: '#697077',
    shade60: '#4D5358',
    shade70: '#343A3F',
    disabledDark: '#697077',
  },

  // Used in RichTextGlobal and RichTextLandingPages
  // So when storybook is using algeaTheme, this line is needed in order for it to work
  // Otherwise storybook won't load stories

  secondary: {
    main: '#C1C7CD',
  } as PaletteColor,
} as Palette;
