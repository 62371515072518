import MobileStepper from '@mui/material/MobileStepper';
import { styled } from '@mui/material/styles';
import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import buttonLeft from '../../../../assets/svg/slider/buttonLeft.svg';
import buttonRight from '../../../../assets/svg/slider/buttonRight.svg';
import { getCmsOptimizedUrl } from '../../../../utils/getCmsOptimizedUrl';
import type { ImagesSliderProps } from './ImagesSlider.types';

const PREFIX = 'ImagesSlider';

const classes = {
  image: `${PREFIX}-image`,
};

const Root = styled('div')(() => ({
  [`& .${classes.image}`]: {
    width: '100%',
  },
}));

export const ImagesSlider: React.FC<ImagesSliderProps> = ({ model }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = model.fields.images?.length || 1;
  const handleNext = React.useCallback(() => {
    setActiveStep((activeStep + 1) % steps);
  }, [steps, activeStep]);
  const handleBack = React.useCallback(() => {
    setActiveStep(activeStep - 1);
  }, [activeStep]);
  return (
    <Root>
      <SwipeableViews
        enableMouseEvents
        onChangeIndex={setActiveStep}
        index={activeStep}>
        {model.fields.images?.map((image) => (
          <img
            alt=""
            className={classes.image}
            width={500}
            key={image.fields.file.url}
            src={getCmsOptimizedUrl({
              url: image.fields.file.url,
              width: 1000,
              quality: 80,
            })}
          />
        ))}
      </SwipeableViews>
      <MobileStepper
        variant="dots"
        steps={steps}
        activeStep={activeStep}
        position="static"
        nextButton={
          <input
            aria-label="slide right button"
            type="image"
            alt="right"
            onClick={handleNext}
            disabled={activeStep === steps - 1}
            src={buttonRight}
          />
        }
        backButton={
          <input
            aria-label="slide left button"
            type="image"
            alt="left"
            onClick={handleBack}
            disabled={activeStep === 0}
            src={buttonLeft}
          />
        }
      />
    </Root>
  );
};
