import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import type { ExpandableImageComponentProps } from './ExpandableImage.types';

const PREFIX = 'ExpandableImage';

const classes = {
  root: `${PREFIX}-root`,
  image: `${PREFIX}-image`,
  fullImage: `${PREFIX}-fullImage`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.root}`]: {
    textAlign: 'center',
    margin: '0px',
    '& figcaption': {
      textAlign: 'center',
      fontStyle: 'italic',
      fontSize: '14px',
      lineHeight: '22px',
      paddingLeft: '20vw',
      paddingRight: '20vw',
      [theme.breakpoints.down('lg')]: {
        paddingLeft: '10vw',
        paddingRight: '10vw',
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '0vw',
        paddingRight: '0vw',
      },
    },
  },

  [`& .${classes.image}`]: {
    objectFit: 'contain',
    cursor: 'pointer',
    width: '100%',
    maxWidth: '75vw',
    height: 'auto',
  },

  [`& .${classes.fullImage}`]: {
    width: '90%',
    maxHeight: '90%',
    margin: '0 auto',
    objectFit: 'scale-down',
  },
}));

const ExpandableImageComponent: React.FC<ExpandableImageComponentProps> = ({
  src,
  caption,
  skipCaption,
}) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  return (
    <Root>
      <figure className={classes.root}>
        <img
          src={src}
          onClick={() => setModalOpen(true)}
          alt={caption}
          className={classes.image}
          title="Click to zoom in"
        />
        {!skipCaption && caption ? <figcaption>{caption}</figcaption> : null}
      </figure>
      <Dialog
        fullScreen
        open={modalOpen}
        onBackdropClick={() => setModalOpen(false)}
        onClose={() => setModalOpen(false)}
        fullWidth>
        <Toolbar>
          <IconButton
            edge="start"
            onClick={() => setModalOpen(false)}
            aria-label="close"
            size="large">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <img src={src} className={classes.fullImage} alt={caption} />
      </Dialog>
    </Root>
  );
};
export const ExpandableImage = ExpandableImageComponent;
