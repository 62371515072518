import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import type { ChangeEvent } from 'react';
import React, { useCallback } from 'react';
import type ReCAPTCHA from 'react-google-recaptcha';
import { Controller, useForm } from 'react-hook-form';
import { colors } from '../../theme/default/color';
import AlertSnackbar from '../AlertSnackbar';
import { FormErrorPopup } from '../FormErrorPopup';
import { FormField } from '../FormField';
import { gaEvent } from '../utils/gaEvent';
import { formSubmitUrl } from './ContactForm.data';
import type { ContactFormProps, ContactFormValues } from './ContactForm.types';
import { StyledRecaptcha } from './styles';

const StyledPolicy = styled('p')({
  fontSize: '14px',
  '& > a': {
    color: colors.newButtonBlue,
  },
});

export const ContactForm: React.FC<ContactFormProps> = ({
  mockOnSubmit,
  cv,
  phone,
  linkedin,
  github,
  subject = 'RF website - Contact Form',
  submitButtonText = 'Submit your message',
}) => {
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
    reset,
  } = useForm<ContactFormValues>();
  const [openSuccessMessage, setOpenSuccessMessage] = React.useState(false);
  const [openErrorMessage, setOpenErrorMessage] = React.useState(false);
  const [cvFileName, setCvFileName] = React.useState('');
  const [captcha, setCaptcha] = React.useState<string | null>(null);

  const captchaRef = React.useRef<ReCAPTCHA>(null);

  const handleCvFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    const cvFile = e.target.files[0];
    if (!cvFile) return;

    const maxFileSize = 4 * 1024 * 1024; //4MB

    if (cvFile.size > maxFileSize) {
      return setError('attachment', {
        type: 'value',
        message: 'Pleace attach file that is smaller than 4MB',
      });
    } else {
      clearErrors('attachment');
    }

    const reader = new FileReader();
    reader.readAsDataURL(cvFile);

    reader.onload = function () {
      const rawFile = reader.result as string;
      setValue('attachment', rawFile);
      setCvFileName(cvFile.name);
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  };

  const sendMessage = useCallback(
    (data: ContactFormValues) => {
      if (!captcha) return;

      gaEvent('WebsiteLead', 'Click');

      const dataWithProperSubject = {
        ...data,
        subject,
      };

      fetch(formSubmitUrl, {
        method: 'POST',
        body: JSON.stringify(dataWithProperSubject, null, 2),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(() =>
          reset({
            fullName: '',
            email: '',
            linkedin: '',
            github: '',
            message: '',
            attachment: undefined,
            //TODO reset to false on ui
            policy: true,
          })
        )
        .then(() => {
          captchaRef.current?.reset();
          setOpenSuccessMessage(true);
        })
        .catch(() => setOpenErrorMessage(true));

      // for testing propose
      mockOnSubmit && mockOnSubmit();
    },
    [reset, mockOnSubmit, subject, captcha]
  );

  return (
    <form>
      <Grid item xs={12} position="relative">
        <Controller
          control={control}
          name="fullName"
          defaultValue=""
          rules={{
            required: 'Empty Field!',
          }}
          render={({ field }) => (
            <FormField
              id="fullName"
              label={'Full name:'}
              invalid={errors.fullName ? 'true' : 'false'}
              {...field}
            />
          )}
        />
        {errors.fullName && (
          <FormErrorPopup>{errors.fullName.message}</FormErrorPopup>
        )}
      </Grid>

      <Grid item xs={12} position="relative">
        <Controller
          control={control}
          name="email"
          defaultValue=""
          rules={{
            required: 'Empty Field!',
            pattern: {
              value: /^[\w.%+-]+@[A-Z\d.-]+\.[A-Z]{2,4}$/i,
              message: 'Please enter a valid email address',
            },
          }}
          render={({ field }) => (
            <FormField
              id="email"
              label={'Email:'}
              invalid={errors.email ? 'true' : 'false'}
              {...field}
            />
          )}
        />
        {errors.email && (
          <FormErrorPopup>{errors.email.message}</FormErrorPopup>
        )}
      </Grid>

      {linkedin && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name="linkedin"
            defaultValue=""
            render={({ field }) => (
              <FormField
                required={false}
                id="linkedin"
                label={'Linkedin:'}
                invalid={errors.linkedin ? 'true' : 'false'}
                {...field}
              />
            )}
          />
        </Grid>
      )}

      {github && (
        <Grid item xs={12}>
          <Controller
            control={control}
            name="github"
            defaultValue=""
            render={({ field }) => (
              <FormField
                required={false}
                id="github"
                label={'Github:'}
                invalid={errors.github ? 'true' : 'false'}
                {...field}
              />
            )}
          />
        </Grid>
      )}
      {phone && (
        <Grid item xs={12} position="relative">
          <Controller
            control={control}
            name="phone"
            defaultValue=""
            rules={{
              required: 'Empty Field!',
              minLength: { value: 9, message: 'This number is to short' },
              maxLength: { value: 12, message: 'This number is to long' },
            }}
            render={({ field }) => (
              <FormField
                type="tel"
                id="phone"
                label={'Phone:'}
                invalid={errors.phone ? 'true' : 'false'}
                {...field}
              />
            )}
          />
          {errors.phone && (
            <FormErrorPopup>{errors.phone.message}</FormErrorPopup>
          )}
        </Grid>
      )}

      <Grid item xs={12} position="relative">
        <Controller
          control={control}
          name="message"
          defaultValue=""
          rules={{
            required: 'Empty Field!',
          }}
          render={({ field }) => (
            <FormField
              id="message"
              label={'Message:'}
              invalid={errors.message ? 'true' : 'false'}
              rows={4}
              {...field}
            />
          )}
        />

        {errors.message && (
          <FormErrorPopup>{errors.message.message}</FormErrorPopup>
        )}
      </Grid>

      {cv && (
        <Grid item xs={12} position="relative">
          <Controller
            control={control}
            name="attachment"
            rules={{ required: 'Please upload CV!' }}
            render={() => (
              <label
                htmlFor="contained-button-file"
                style={{ marginRight: '1rem' }}>
                <input
                  style={{ display: 'none' }}
                  id="contained-button-file"
                  name="cv"
                  accept=".pdf,.doc,.docx"
                  type="file"
                  onChange={(e) => handleCvFileChange(e)}
                />
                <Grid
                  display="flex"
                  container
                  direction={{ xs: 'column', md: 'row' }}
                  alignItems="center">
                  <Button
                    sx={{ mr: 2, mb: { xs: 2, md: 0 }, maxWidth: '12rem' }}
                    variant="contained"
                    color="secondary"
                    component="span">
                    Upload CV
                  </Button>
                  {cvFileName && <span>{cvFileName}</span>}
                </Grid>
              </label>
            )}
          />
          {errors.attachment && (
            <FormErrorPopup>{errors.attachment.message}</FormErrorPopup>
          )}
        </Grid>
      )}

      <Grid item xs={12} position="relative" pb={3}>
        <FormControlLabel
          name="policy"
          defaultValue="false"
          label={
            <StyledPolicy>
              I accept Rumble Fish{' '}
              <a href="/privacy-policy/" target="_blank">
                Privacy Policy
              </a>
            </StyledPolicy>
          }
          control={
            <Controller
              control={control}
              name="policy"
              defaultValue={false}
              rules={{
                validate: (value) =>
                  value === true ||
                  'If you want to continue accept our privacy policy',
              }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Checkbox
                  data-testid={'policy'}
                  value={value}
                  onChange={(e) => onChange(e.target.checked)}
                  onBlur={onBlur}
                />
              )}
            />
          }
        />
        {errors.policy && (
          <FormErrorPopup>{errors.policy.message}</FormErrorPopup>
        )}
      </Grid>
      <StyledRecaptcha
        sitekey={process.env.CAPTCHA_KEY as string}
        onChange={(value) => setCaptcha(value)}
        ref={captchaRef}
      />
      <Grid item xs={12}>
        {cv ? (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSubmit((data) => sendMessage(data))}>
            Apply
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSubmit((data) => sendMessage(data))}>
            {submitButtonText}
          </Button>
        )}
      </Grid>
      <AlertSnackbar
        openSuccessMessage={openSuccessMessage}
        setOpenSuccessMessage={setOpenSuccessMessage}
        openErrorMessage={openErrorMessage}
        setOpenErrorMessage={setOpenErrorMessage}
        succesMessage="Your message has been sent successfully. We will get back to you as soon as possible."
        errorMessage="There was an error trying to send your message. Please try again later or send us an email."
      />
    </form>
  );
};
