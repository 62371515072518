import { styled } from '@mui/material/styles';
import React from 'react';
import type { ImageProps } from './Image.types';

const PREFIX = 'Image';

const classes = {
  image: `${PREFIX}-image`,
};

const Root = styled('div')<ImageProps>(({ theme, model }) => ({
  [`&.${classes.image}`]: {
    height: model.fields.height,
    width: '100%',
    position: 'relative',
    background: `url(${model.fields.image.fields.file.url}) no-repeat`,
    backgroundSize: 'contain',
    backgroundPosition: model.fields.align,
    [theme.breakpoints.down('lg')]: {
      backgroundPosition: 'center',
    },
  },
}));

export const Image: React.FC<ImageProps> = ({ model }) => {
  return <Root model={model} className={classes.image} />;
};
