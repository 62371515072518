import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import type { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import bash from 'react-syntax-highlighter/dist/cjs/languages/hljs/bash';
import javascript from 'react-syntax-highlighter/dist/cjs/languages/hljs/javascript';
import json from 'react-syntax-highlighter/dist/cjs/languages/hljs/json';
import kotlin from 'react-syntax-highlighter/dist/cjs/languages/hljs/kotlin';
import yaml from 'react-syntax-highlighter/dist/cjs/languages/hljs/yaml';
import solidity from 'react-syntax-highlighter/dist/cjs/languages/prism/solidity';
import { default as SyntaxHighlighterPrism } from 'react-syntax-highlighter/dist/cjs/prism-light';
import docco from 'react-syntax-highlighter/dist/cjs/styles/hljs/docco';
import prism from 'react-syntax-highlighter/dist/cjs/styles/prism/prism';
import { colors } from '../../../theme/default/color';
import type { RichTextProps } from '../RichText.types';
import ActionButton from './ActionButton';
import Anchor from './Anchor';
import CaseStudyTestimonial from './CaseStudyTestimonial';
import CaseStudyTestimonialInline from './CaseStudyTestimonialInline';
import CurrentOpenings from './CurrentOpenings';
import DownloadButton from './DownloadButton';
import ExpandableImage from './ExpandableImage';
import Headline from './Headline';
import HubspotForm from './HubspotForm';
import IconCardsCollection from './IconCardsCollection';
import Image from './Image';
import ImagesGrid from './ImagesGrid';
import ImagesSlider from './ImagesSlider';
import InstagramPosts from './InstagramPosts';
import LinkImage from './LinkImage';
import PersonCard from './PersonCard';
import TextBlockGrid from './TextBlockGrid';
import TilesCollection from './TilesCollection';

const PREFIX = 'RichTextGlobal';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    color: theme.palette.primary.main,
    padding: '0 20px 0 20px',
    '& pre': {
      fontFamily: 'Consolas,monospace',
      display: 'block',
      backgroundColor: colors.black,
      height: 'auto',
      overflow: 'auto',
      margin: '20px 0px',
      color: colors.white,
      fontSize: '14px',
      borderRadius: '5px',
      '& a': {
        color: colors.white,
      },
    },
    '& h3': {
      fontSize: '22px',
      fontWeight: 'bold',
    },
    '& h4': {
      color: colors.black,
      position: 'relative !important',
      display: 'table',
      textAlign: 'left',
      fontSize: '26px',
      fontWeight: 'bold',
      marginBottom: '40px',
      marginRight: 'auto',
      '&:after': {
        content: "''",
        height: '3px',
        width: '40px',
        background: colors.blue,
        position: 'absolute',
        left: '20px',
        bottom: '-5px',
      },
    },
    '& ul': {
      fontSize: '16px',
      textAlign: 'left',
    },
    '& li': {
      listStyle: 'none',
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: '9px',
        top: '-2px',
        position: 'relative',
        height: '9px',
        borderRadius: '5px',
        marginRight: '10px',
        background: theme.palette.secondary.main,
      },
      '& fragment>ul>li': {
        '&:before': {
          content: '"unset !important"',
        },
      },
      '& p': {
        display: 'inline',
      },
    },
    '& code': {
      display: 'block',
      padding: '20px',
      whiteSpace: 'pre-wrap',
    },
  },
}));

SyntaxHighlighter.registerLanguage('javascript', javascript);
SyntaxHighlighter.registerLanguage('bash', bash);
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('yaml', yaml);
SyntaxHighlighterPrism.registerLanguage('solidity', solidity);
SyntaxHighlighter.registerLanguage('kotlin', kotlin);

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      return (
        <ExpandableImage
          caption={node.data.target.fields.description}
          src={node.data.target.fields.file.url}
        />
      );
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      if (node.data.target.sys.contentType.sys.id === 'codeSnippet') {
        return (
          <SyntaxHighlighter
            language={node.data.target.fields.language}
            style={
              node.data.target.fields.language !== 'solidity' ? docco : prism
            }>
            {node.data.target.fields.body}
          </SyntaxHighlighter>
        );
      } else if (node.data.target.sys.contentType.sys.id === 'imagesGrid') {
        return <ImagesGrid model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'textBlockGrid') {
        return <TextBlockGrid model={node.data.target} />;
      } else if (
        node.data.target.sys.contentType.sys.id === 'caseStudyTestimonial'
      ) {
        if (node.data.target.fields.style === 'normal') {
          return <CaseStudyTestimonial model={node.data.target} />;
        } else {
          return <CaseStudyTestimonialInline model={node.data.target} />;
        }
      } else if (node.data.target.sys.contentType.sys.id === 'hubspotForm') {
        return <HubspotForm model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'downloadButton') {
        return <DownloadButton model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'actionButton') {
        return <ActionButton model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'imageSlider') {
        return <ImagesSlider model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'linkImage') {
        return <LinkImage model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'image') {
        return <Image model={node.data.target} />;
      } else if (
        node.data.target.sys.contentType.sys.id === 'tilesCollection'
      ) {
        return <TilesCollection model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'instagramPosts') {
        return <InstagramPosts model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'personCard') {
        return <PersonCard model={node.data.target} />;
      } else if (
        node.data.target.sys.contentType.sys.id === 'currentOpenings'
      ) {
        return <CurrentOpenings model={node.data.target} />;
      } else if (node.data.target.sys.contentType.sys.id === 'anchor') {
        return <Anchor model={node.data.target} />;
      } else if (
        node.data.target.sys.contentType.sys.id === 'iconCardsCollection'
      ) {
        return <IconCardsCollection model={node.data.target} />;
      } else {
        return null;
      }
    },
    [BLOCKS.HEADING_1]: (_node: unknown, children: React.ReactNode) => {
      return <Typography variant="h1">{children}</Typography>;
    },
    [BLOCKS.HEADING_2]: (_node: unknown, children: React.ReactNode) => {
      // @ts-ignore: Object is possibly 'null'.
      const header = children[0];
      if (typeof header === 'object' && header.type === 'u') {
        return <Headline headline={header.props.children} />;
      } else {
        return <Typography variant="h2">{children}</Typography>;
      }
    },
    [BLOCKS.HEADING_3]: (_node: unknown, children: React.ReactNode) => {
      return <Typography variant="h3">{children}</Typography>;
    },
    [BLOCKS.HEADING_4]: (_node: unknown, children: React.ReactNode) => {
      return <Typography variant="h4">{children}</Typography>;
    },
    [BLOCKS.PARAGRAPH]: (_node: unknown, children: React.ReactNode) => {
      return (
        <Typography component="p" variant="body1">
          {children}
        </Typography>
      );
    },
  },
};

export const RichTextGlobal: React.FC<RichTextProps> = ({ content }) => {
  return (
    <Root className={classes.root}>
      {documentToReactComponents(content, renderOptions)}
    </Root>
  );
};
