import Grid from '@mui/material/Grid';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import React from 'react';
import ExpandableImage from '../ExpandableImage';
import type { ImagesGridProps } from './ImagesGrid.types';

const PREFIX = 'ImagesGrid';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: '100px',
    [theme.breakpoints.down('lg')]: {
      marginTop: '50px',
    },
    [theme.breakpoints.up('lg')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
}));

export const ImagesGrid: React.FC<ImagesGridProps> = ({ model }) => {
  return (
    <StyledGrid
      container
      className={classes.root}
      justifyContent="space-around"
      spacing={4}>
      {model &&
        model.fields?.images?.map((image) => (
          <Grid item key={image.sys.id} md={6} sm={10}>
            <ExpandableImage
              src={image.fields.file.url}
              caption={image.fields.description}
              skipCaption
            />
          </Grid>
        ))}
    </StyledGrid>
  );
};
