import type { ParsedUrlQueryInput } from 'node:querystring';
import type { Theme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import TypoGraphy from '@mui/material/Typography';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import arrowRight from '../../assets/svg/icons/arrowRightBlue.svg';
import facebook from '../../assets/svg/icons/socialGray/facebook.svg';
import linkedin from '../../assets/svg/icons/socialGray/linkedin.svg';
import medium from '../../assets/svg/icons/socialGray/medium.svg';
import twitter from '../../assets/svg/icons/socialGray/twitter.svg';
import { colors } from '../../theme/default/color';
import { formatDate } from '../../utils/formatDate';
import type { BlogSidebarProps } from './BlogSidebar.type';

const PREFIX = 'BlogSidebar';

const classes = {
  root: `${PREFIX}-root`,
  categoryContainer: `${PREFIX}-categoryContainer`,
  sectionTitle: `${PREFIX}-sectionTitle`,
  categoryButton: `${PREFIX}-categoryButton`,
  categoryName: `${PREFIX}-categoryName`,
  line: `${PREFIX}-line`,
  pubDateText: `${PREFIX}-pubDateText`,
  imageLatest: `${PREFIX}-imageLatest`,
  articleTitle: `${PREFIX}-articleTitle`,
  socialList: `${PREFIX}-socialList`,
  socialListItem: `${PREFIX}-socialListItem`,
  socialIcon: `${PREFIX}-socialIcon`,
};

const StyledBlogSidebar = styled('div')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.categoryContainer}`]: {
    backgroundColor: colors.white,
    boxShadow: '0px 0px 11px -2px rgba(179,179,179,1)',
    marginTop: '100px',
    fontSize: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    borderRadius: '10px 0 0 10px',
    padding: '40px 40px',
  },

  [`& .${classes.sectionTitle}`]: {
    fontSize: '22px',
    marginTop: '0',
    marginBottom: '40px',
    paddingTop: '0',
    paddingBottom: '8px',
  },

  [`& .${classes.categoryButton}`]: {
    backgroundColor: 'transparent',
    border: 'none',
  },

  [`& .${classes.categoryName}`]: {
    textDecoration: 'none',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    fontSize: '16px',
    color: colors.black,
    textTransform: 'capitalize',
    transition: '0.5s',
    '&>img': {
      opacity: '0',
      marginRight: '-10px',
    },
    '&:hover': {
      color: colors.blue,
      '&>img': {
        transition: '0.5s',
        marginRight: '15px',
        opacity: '1',
      },
    },
  },

  [`& .${classes.line}`]: {
    border: 'solid 0.5px' + colors.lightgray250,
  },

  [`& .${classes.pubDateText}`]: {
    fontSize: '14px',
    color: colors.mediumgray400,
  },

  [`& .${classes.imageLatest}`]: {
    width: '57px',
    height: '57px',
    objectFit: 'cover',
    borderRadius: '10px',
  },

  [`& .${classes.articleTitle}`]: {
    cursor: 'pointer',
    transition: '0.5s',
    '&:hover': {
      color: colors.blue,
      transition: '0.5s',
    },
  },

  [`& .${classes.socialList}`]: {
    display: 'flex',
  },

  [`& .${classes.socialListItem}`]: {
    padding: '0px',
    marginLeft: '10px',
  },

  [`& .${classes.socialIcon}`]: {
    '&:hover': {
      opacity: '0.8',
    },
  },
}));

export type QueryType = string | ParsedUrlQueryInput | null | undefined;

export const BlogSidebar: React.FC<BlogSidebarProps> = ({
  categoryList,
  latest,
}) => {
  const router = useRouter();

  const handleRedirect = (query: QueryType) =>
    router.push(
      {
        pathname: '/blog',
        query,
      },
      undefined,
      { shallow: true }
    );

  return (
    <Hidden only={['xs', 'sm', 'md']}>
      <Grid item xs={12} md={5} sm={3} lg={3} xl={3}>
        <StyledBlogSidebar>
          <Grid item xs={12} className={classes.categoryContainer}>
            <TypoGraphy variant="overline" className={classes.sectionTitle}>
              Categories
            </TypoGraphy>
            {categoryList.map((category: string) => (
              <div key={category}>
                <Grid container alignItems="start" my={2}>
                  <button
                    className={classes.categoryButton}
                    onClick={() => {
                      handleRedirect({
                        category: category.toLowerCase().trim(),
                        page: 1,
                      });
                    }}>
                    <TypoGraphy className={classes.categoryName} variant="h6">
                      <img src={arrowRight} alt="arrow" width="8" height="13" />
                      {category}
                    </TypoGraphy>
                  </button>
                </Grid>
                <div className={classes.line} />
              </div>
            ))}
          </Grid>
          <Grid
            style={{ marginTop: '50px' }}
            item
            xs={12}
            className={classes.categoryContainer}>
            <TypoGraphy variant="overline" className={classes.sectionTitle}>
              Recent posts
            </TypoGraphy>
            {latest.map((article) => (
              <Grid
                key={article.sys.id}
                container
                style={{ marginBottom: '30px' }}
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                <Grid item xs={12}>
                  <TypoGraphy align="left" className={classes.pubDateText}>
                    {formatDate(article.fields.pubDate)}
                  </TypoGraphy>
                </Grid>
                <Grid item md={2}>
                  <img
                    className={classes.imageLatest}
                    src={article.fields.image.fields.file.url}
                    alt="article miniature"
                    width="57"
                    height="57"
                  />
                </Grid>
                <Grid item md={8}>
                  <NextLink href={'/blog/post/' + article.fields.slug + '/'}>
                    <TypoGraphy
                      variant="h6"
                      align="left"
                      className={classes.articleTitle}>
                      {article.fields.title.toString().slice(0, 35) + '...'}
                    </TypoGraphy>
                  </NextLink>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Grid
            item
            style={{ marginTop: '50px' }}
            xs={12}
            className={classes.categoryContainer}>
            <TypoGraphy variant="overline" className={classes.sectionTitle}>
              Follow Us
            </TypoGraphy>
            <List className={classes.socialList}>
              {/* TODO remove duplicated code */}
              <ListItem className={classes.socialListItem}>
                <a
                  href="https://www.facebook.com/rumblefishsoftwaredevelopment"
                  target="_blank"
                  rel="noreferrer">
                  {' '}
                  <img
                    className={classes.socialIcon}
                    src={facebook}
                    alt="Facebook"
                    width="35"
                    height="35"
                  />
                </a>
              </ListItem>
              <ListItem className={classes.socialListItem}>
                <a
                  href="https://www.linkedin.com/company/rumblefishdev"
                  target="_blank"
                  rel="noreferrer">
                  {' '}
                  <img
                    className={classes.socialIcon}
                    src={linkedin}
                    alt="LinkedIn"
                    width="35"
                    height="35"
                  />
                </a>
              </ListItem>
              <ListItem className={classes.socialListItem}>
                <a
                  href="https://medium.com/@rumblefishdev"
                  target="_blank"
                  rel="noreferrer">
                  {' '}
                  <img
                    className={classes.socialIcon}
                    src={medium}
                    alt="Medium"
                    width="35"
                    height="35"
                  />
                </a>
              </ListItem>
              <ListItem className={classes.socialListItem}>
                <a
                  href="https://twitter.com/rumblefishdev"
                  target="_blank"
                  rel="noreferrer">
                  {' '}
                  <img
                    className={classes.socialIcon}
                    src={twitter}
                    alt="Twitter"
                    width="35"
                    height="35"
                  />
                </a>
              </ListItem>
            </List>
          </Grid>
        </StyledBlogSidebar>
      </Grid>
    </Hidden>
  );
};
