import { Link as MUILink } from '@mui/material';
import NextLink from 'next/link';
import React, { useState } from 'react';
import type { NavLinkProps } from './NavLink.types';

export const NavLink: React.FC<NavLinkProps> = ({
  className,
  activeClassName,
  to,
  label,
  onClick,
  children,
}) => {
  const [focused, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <NextLink href={to} passHref>
      <MUILink
        onFocus={onFocus}
        onBlur={onBlur}
        aria-label={label}
        className={focused ? activeClassName : className}
        onClick={onClick}
        underline="none">
        {children}
      </MUILink>
    </NextLink>
  );
};
