import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Link from '@mui/material/Link';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import React from 'react';
import MailIcon from '../../assets/svg/icons/mail.svg';
import PhoneIcon from '../../assets/svg/icons/phone.svg';
import PointerIcon from '../../assets/svg/icons/pointer.svg';
import { Divider } from '../../components/Divider';
import { colors } from '../../theme/default/color';
import {
  coinsList,
  menuItemsList,
  servicesItemsList,
  socialMediaList,
} from './Footer.data';
import type { FooterLinks } from './Footer.types';
import { FooterLinksSection, FooterSection, IconText } from './Footer.utils';

const PREFIX = 'Footer';

const classes = {
  footerRoot: `${PREFIX}-footerRoot`,
  footerContainer: `${PREFIX}-footerContainer`,
  footerContentContainer: `${PREFIX}-footerContentContainer`,
  footerCopyright: `${PREFIX}-footerCopyright`,
};

const Root = styled('footer')(
  ({
    theme: {
      spacing,
      breakpoints,
      template: { normalSectionWidth },
    },
  }: {
    theme: Theme;
  }) => ({
    [`&.${classes.footerRoot}`]: {
      '& h4': {
        color: colors.white,
      },
      backgroundColor: colors.darkblue,
      padding: spacing(4),
      fontFeatureSettings: "'pnum' on, 'lnum' on;",
    },

    [`& .${classes.footerContainer}`]: {
      maxWidth: normalSectionWidth,
      margin: '0 auto',
      display: 'grid',
      gap: spacing(4),
    },

    [`& .${classes.footerContentContainer}`]: {
      display: 'grid',
      gridAutoFlow: 'column',

      [breakpoints.down('md')]: {
        gridAutoFlow: 'row',
        gap: spacing(2),
        justifyItems: 'center',
      },
    },

    [`& .${classes.footerCopyright}`]: {
      textAlign: 'center',
      color: colors.white,
      opacity: 0.7,
      fontSize: '0.75rem',
      fontFamily: 'Axia Black',
      fontStyle: 'normal',
    },
  })
);

const StyledContactLinks = styled('a')({
  textDecoration: 'none',
});

const BusinnesInfo: React.FC = () => {
  return (
    <FooterSection heading="RUMBLE FISH POLAND SP. Z O.O">
      <Grid item>
        <IconText alt="pointer" icon={PointerIcon}>
          Filipa Eisenberga 11/3 <br /> 31-523 Kraków, Poland <br />
          PL6772425725
        </IconText>
      </Grid>
      <Grid item>
        <StyledContactLinks href="tel:+48 737 455 594">
          <IconText alt="phone" icon={PhoneIcon}>
            +48 737 455 594
          </IconText>
        </StyledContactLinks>
      </Grid>
      <Grid item>
        <StyledContactLinks target="_self" href="mailto:hello@rumblefish.dev">
          <IconText alt="mail" icon={MailIcon}>
            hello@rumblefish.dev
          </IconText>
        </StyledContactLinks>
      </Grid>
    </FooterSection>
  );
};

const FooterMenu: React.FC<FooterLinks> = ({ linksList }) => (
  <FooterLinksSection heading="Menu" linksList={linksList} />
);

const Services: React.FC<FooterLinks> = ({ linksList }) => (
  <FooterLinksSection heading="Services" linksList={linksList} />
);

const SocialMediaAndPayments: React.FC = () => {
  return (
    <Grid item container rowGap={3} direction="column">
      <FooterSection vertical heading="Social Media">
        {socialMediaList.map((social) => (
          <Link key={social.to} aria-label={social.label} href={social.to}>
            <img
              src={social.imgSrc}
              alt={social.label}
              width={24}
              height={24}
            />
          </Link>
        ))}
      </FooterSection>
      <FooterSection vertical heading="We accept BTC/ETH/DAI">
        {coinsList.map((coin) => (
          <img
            key={coin.label}
            src={coin.imgSrc}
            alt={coin.label}
            width={24}
            height={24}
          />
        ))}
      </FooterSection>
    </Grid>
  );
};

export const Footer: React.FC = () => {
  return (
    <Root className={classes.footerRoot}>
      <div className={classes.footerContainer}>
        <div className={classes.footerContentContainer}>
          <Hidden mdDown>
            <FooterMenu linksList={menuItemsList.linksList} />
            <Services linksList={servicesItemsList.linksList} />
          </Hidden>
          <BusinnesInfo />
          <SocialMediaAndPayments />
        </div>
        <Divider />
        <p className={classes.footerCopyright}>
          © COPYRIGHT {new Date().getFullYear()} #RUMBLEFISHDEV - ALL RIGHTS
          RESERVED
        </p>
      </div>
    </Root>
  );
};
