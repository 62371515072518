/* eslint-disable @typescript-eslint/naming-convention */
import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import noiseBg from '@rumblefish/web-next/public/png/noise.png';
import { breakpoints } from './breakpoints';
import { mixins } from './mixins';
import { overrides as components } from './overrides';
import { palette, paletteDark } from './palette';
import type { ExtendedTypographyPropsVariantOverrides1 } from './typography';
import { typography } from './typography';
import type { ExtendedTypographyPropsVariantOverrides2 } from './typography2';
import { typography2 } from './typography2';
import { fluidFont } from './utilis';

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides
    extends ExtendedTypographyPropsVariantOverrides1,
      ExtendedTypographyPropsVariantOverrides2 {}
}
declare module '@mui/material/styles' {
  interface Theme {
    utilis: {
      isMobile: boolean;
      fluidFont: (min: number, max: number) => string;
    };
  }
  interface ThemeOptions {
    utilis?: {
      isMobile: boolean;
      fluidFont: (min: number, max: number) => string;
    };
  }
}

const spacing = 8;

export const theme = createTheme({
  palette,
  spacing,
  typography: { ...typography, ...typography2 },
  components,
  breakpoints,
  mixins,
  utilis: {
    isMobile: global.innerWidth <= 960,
    fluidFont: fluidFont,
  },
} as ThemeOptions);

export const themeDark = createTheme({
  palette: paletteDark,
  spacing,
  typography: { ...typography, ...typography2 },
  components: {
    MuiTypography: {
      styleOverrides: { ...components.MuiTypography },
    },
    MuiCssBaseline: {
      styleOverrides:
        components?.MuiCssBaseline?.styleOverrides +
        `
          body {
            background: url(${noiseBg}), #121B1C;
          }
        `,
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter',
        },
      },
    },
  },
  breakpoints,
  mixins,
  utilis: {
    isMobile: global.innerWidth <= 960,
    fluidFont: fluidFont,
  },
} as ThemeOptions);
