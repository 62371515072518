import { keyframes, styled, Typography } from '@mui/material';

const easyTransition = 'all 0.45s ease-in-out';

const paperPlaneAnimation = keyframes`
      0% {
        transform: scale3d(1, 1, 1);
        bottom: 0;
        left: 0;
      }
      30% {
        transform: scale3d(1.2, 0.4, 1);
      }
      35% {
        bottom: -10px;
        left: -10px;
      }
      45% {
        transform: scale3d(1, 1, 1);

				bottom: 20px;
        left: 20px;
      }
      50% {
				bottom: 30px;
        left: 30px;
      }
      65% {
				bottom: 50px;
        left: 50px;
      }
      70% {
				bottom: 60px;
        left: 60px;
      }
      75% {
        left: -2000px;
      }
      80% {
        bottom: -2000px;
      }
      85% {
        left: -50px;
        bottom: -50px;
      }
      90% {
        left: -30px;
        bottom: -30px;
      }
      100% {
        bottom: 0;
        left: 0;
      }
`;

export const StyledButton = styled('button', {
  shouldForwardProp: (prop) =>
    prop !== 'backgroundColor' && prop !== 'animation',
})<{ backgroundColor: string; animation: string }>(
  ({ backgroundColor, animation, theme: { breakpoints } }) => ({
    transition: easyTransition,
    position: 'fixed',
    bottom: '30px',
    right: '30px',
    display: 'block',
    cursor: 'pointer',
    outline: 'none',
    width: '4rem',
    border: 0,
    verticalAlign: 'middle',
    borderRadius: '50%',
    background: backgroundColor,
    textDecoration: 'none',
    padding: 0,
    transformStyle: 'preserve-3d',
    ['&::before']: {
      transition: easyTransition,
      content: "''",
      display: 'inline-block',
      position: 'absolute',
      transform: 'translateZ(-1px)',
      top: 0,
      right: 0,
      bottom: 0,
      width: 0,
      marginRight: '2rem',
      height: '4rem',
      background: backgroundColor,
      borderTopLeftRadius: '32px',
      borderBottomLeftRadius: '32px',
    },
    [breakpoints.up('lg')]: {
      ['&:hover']: {
        backgroundColor: '#01196F',
        ['&::before']: {
          width: '8rem',
          backgroundColor: '#01196F',
        },
        ['& *']: {
          transition: easyTransition,
          opacity: 1,
        },
        ['& img']: {
          animation:
            animation === 'paperPlane'
              ? `${paperPlaneAnimation} 0.65s both`
              : 'none',
        },
      },
    },
    [breakpoints.down('md')]: {
      height: '40px',
      width: '40px',
      bottom: '18px',
      right: '24px',
    },
  })
);

export const StyledCircle = styled('span')(({ theme: { breakpoints } }) => ({
  transition: easyTransition,
  position: 'relative',
  display: 'block',
  float: 'right',
  height: '4rem',
  width: '4rem',
  overflow: 'hidden',
  borderRadius: '1.625rem',
  [breakpoints.down('md')]: {
    height: '40px',
    width: '40px',
  },
  ['& img']: {
    maxWidth: '100%',
    position: 'relative',
  },
}));

export const StyledMovableText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'textColor',
})<{ textColor: string }>(({ textColor }) => ({
  transition: easyTransition,
  position: 'absolute',
  right: '40px',
  top: 0,
  bottom: 0,
  margin: '0 2rem 0 0',
  alignItems: 'center',
  color: textColor,
  opacity: 0,
  paddingTop: '14%',
  fontWeight: '700',
  zIndex: 10,
  textAlign: 'center',
}));
