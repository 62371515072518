import { styled } from '@mui/material/styles';
import NextLink from 'next/link';
import React from 'react';
import type { ImageProps, LinkImageProps } from './LinkImage.types';

const StyledImage = styled('img')(() => ({
  objectFit: 'contain',
  cursor: 'pointer',
  width: '100%',
  maxWidth: '75vw',
  height: 'auto',
}));

const StyledLink = styled('a')({
  display: 'inline-block',
});

const Image = ({ src, title }: ImageProps) => {
  return <StyledImage width="281" height="187" src={src} title={title} />;
};

export const LinkImage: React.FC<LinkImageProps> = ({ model }) => {
  const type = model.fields.type;
  const url = model.fields.url;
  const src = model.fields?.image?.fields?.file?.url;
  const title = model.fields.title;

  if (type === 'internal')
    return (
      <NextLink href={url} passHref>
        <StyledLink>
          <Image src={src} title={title} />
        </StyledLink>
      </NextLink>
    );
  if (type === 'external')
    return (
      <StyledLink href={url}>
        <Image src={src} title={title} />
      </StyledLink>
    );
  return <Image src={src} title={title} />;
};
