import { Button, Grid, Typography } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import NextLink from 'next/link';
import React from 'react';
import type { OpeningItemProps } from './OpeningItem.types';

const PREFIX = 'OpeningItem';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(2),
    width: '100%',
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    textAlign: 'center',
  },
}));

export const OpeningItem: React.FC<OpeningItemProps> = ({ opening }) => {
  return (
    <StyledGrid container alignItems="center" className={classes.root}>
      <Grid item xs={12} md={2}>
        <img
          src={opening.fields.icon?.fields.file.url}
          alt={opening.fields.icon?.fields.title}
          width="120"
          height="120"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="h5">{opening.fields.title}</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography>{opening.fields.salary}</Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <NextLink href={`/careers/${opening.fields.slug}/`}>
          <Button
            sx={{ py: 1, px: 3, mt: { xs: 3, sm: 0 } }}
            color="secondary"
            variant="outlined">
            Learn More
          </Button>
        </NextLink>
      </Grid>
    </StyledGrid>
  );
};
