import { Button, Theme } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import MobileStepper from '@mui/material/MobileStepper';
import { styled } from '@mui/material/styles';
import TypoGraphy from '@mui/material/Typography';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import buttonLeft from '../../../src/assets/svg/slider/buttonLeft.svg';
import buttonRight from '../../../src/assets/svg/slider/buttonRight.svg';
import { colors } from '../../theme/default/color';
import { formatDate } from '../../utils/formatDate';
import { NavigationButton } from '../NavigationButton';
import { NavLink } from '../NavLink';
import NextLink from 'next/link';
import type {
  DotsStepperMobileProps,
  LatestArticlesProps,
} from './LatestArticles.types';

const PREFIX = 'LatestArticles';

const classes = {
  root: `${PREFIX}-root`,
  cardArticle: `${PREFIX}-cardArticle`,
  grid: `${PREFIX}-grid`,
  image: `${PREFIX}-image`,
  container: `${PREFIX}-container`,
  articleTitle: `${PREFIX}-articleTitle`,
  cardContainer: `${PREFIX}-cardContainer`,
  pubDateText: `${PREFIX}-pubDateText`,
  button: `${PREFIX}-button`,
  navlink: `${PREFIX}-navlink`,
  imgContainer: `${PREFIX}-imgContainer`,
  textContainer: `${PREFIX}-textContainer`,
  displayNone: `${PREFIX}-displayNone`,
  arrows: `${PREFIX}-arrows`,
  title: `${PREFIX}-title`,
};

const LatestArticleWrapper = styled('div')(
  ({ theme: { palette, breakpoints } }: { theme: Theme }) => ({
    [breakpoints.down('md')]: {
      maxWidth: '92vw',
      margin: '0 auto',
    },
    color: palette.primary.main,

    [`& .${classes.cardArticle}`]: {
      cursor: 'pointer',
      maxWidth: '330px',
      width: '100%',
      height: '460px',
      borderRadius: '10px',
      boxShadow: '0px 0px 16px -6px rgba(0,0,0,0.75)',
      [breakpoints.down('sm')]: {
        maxWidth: '275px',
      },
    },

    [`& .${classes.grid}`]: {
      marginBottom: '30px',
      textAlign: 'center',
    },

    [`& .${classes.image}`]: {
      width: '100%',
      height: '157px',
      objectFit: 'cover',
      marginBottom: '40px',
    },

    [`& .${classes.container}`]: {
      marginBottom: '40px',
    },

    [`& .${classes.articleTitle}`]: {
      position: 'relative !important' as 'relative',
      display: 'table',
      margin: '20px',
      width: '80%',
      [breakpoints.down('sm')]: {
        fontSize: '14px',
      },
      '&:after': {
        content: "''",
        height: '6px',
        width: '30%',
        background: colors.blue,
        position: 'absolute',
        left: 'calc(30% - 30%)',
        top: '-10px',
      },
    },

    [`& .${classes.cardContainer}`]: {
      height: '370px',
    },

    [`& .${classes.pubDateText}`]: {
      fontSize: '13px',
      color: colors.black,
      marginTop: '20px',
      paddingLeft: '16px',
      width: '80%',
    },

    [`& .${classes.button}`]: {
      marginTop: '20px',
      width: '120px',
    },

    [`& .${classes.navlink}`]: {
      textDecoration: 'none',
      color: colors.black,
      transition: '0.5s',
      '&:hover': {
        color: colors.blue,
        transition: '0.5s',
      },
    },

    [`& .${classes.imgContainer}`]: {
      height: '170px',
    },

    [`& .${classes.textContainer}`]: {},

    [`& .${classes.displayNone}`]: {
      display: 'none',
    },

    [`& .${classes.arrows}`]: {
      margin: '0px 30px 0px 30px',
    },

    [`& .${classes.title}`]: {
      margin: '50px 0',
      padding: '20px',
    },
  })
);

const DotsStepperMobile: React.FC<DotsStepperMobileProps> = ({ data }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === Math.ceil(data.length) - 1) {
      setActiveStep(0);
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStepChange = (activeStep: number) => {
    setActiveStep(activeStep);
  };

  return (
    <LatestArticleWrapper>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center">
        <Grid item xs={12} lg={8}>
          <SwipeableViews
            enableMouseEvents
            onChangeIndex={handleStepChange}
            index={activeStep}>
            {data.map((article) => (
              <Grid
                item
                className={classes.grid}
                xs={12}
                key={article.sys.id}
                container
                justifyContent="center">
                <Grid item>
                  <NextLink href={'/blog/post/' + article.fields.slug + '/'}>
                    <Card className={classes.cardArticle}>
                      <Grid className={classes.cardContainer}>
                        <Grid className={classes.imgContainer}>
                          <img
                            className={classes.image}
                            src={article.fields.image.fields.file.url}
                            alt="article headline"
                          />
                        </Grid>
                        <Grid className={classes.textContainer}>
                          <TypoGraphy
                            align="left"
                            className={classes.articleTitle}
                            variant="h4">
                            {article.fields.title}
                          </TypoGraphy>
                          <TypoGraphy
                            align="left"
                            className={classes.pubDateText}>
                            {formatDate(article.fields.pubDate)}
                          </TypoGraphy>
                        </Grid>
                      </Grid>
                      <Button
                        sx={{ paddingX: 8 }}
                        variant="contained"
                        color="primary">
                        More
                      </Button>
                    </Card>
                  </NextLink>
                </Grid>
              </Grid>
            ))}
          </SwipeableViews>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MobileStepper
          variant="dots"
          steps={data.length}
          activeStep={activeStep}
          position="static"
          nextButton={
            <input
              aria-label="slide right button"
              className={classes.arrows}
              type="image"
              alt="right"
              onClick={handleNext}
              src={buttonRight}
            />
          }
          backButton={
            <input
              aria-label="slide left button"
              className={classes.arrows}
              type="image"
              alt="left"
              onClick={handleBack}
              disabled={activeStep === 0}
              src={buttonLeft}
            />
          }
        />
      </Grid>
    </LatestArticleWrapper>
  );
};

export const LatestArticles: React.FC<LatestArticlesProps> = ({
  showTitle,
  title,
  latest,
}) => {
  return (
    <LatestArticleWrapper>
      <div className={classes.root}>
        <Grid
          className={classes.container}
          container
          direction="row"
          justifyContent="center"
          alignItems="center">
          <Grid item xs={12} xl={8}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end">
              <Grid item xs={12}>
                <TypoGraphy
                  className={showTitle ? classes.displayNone : classes.title}
                  variant="h3"
                  align="center">
                  Get the latest technology insights on our blog
                </TypoGraphy>
                <TypoGraphy
                  className={showTitle ? undefined : classes.displayNone}
                  variant="overline">
                  {title}
                </TypoGraphy>
              </Grid>
              <Hidden lgUp>
                <DotsStepperMobile data={latest} />
              </Hidden>
              <Hidden lgDown>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-evenly"
                  alignItems="center">
                  {latest.map((article) => (
                    <Grid item className={classes.grid} key={article.sys.id}>
                      <NextLink
                        href={'/blog/post/' + article.fields.slug + '/'}>
                        <Card className={classes.cardArticle}>
                          <Grid className={classes.cardContainer}>
                            <Grid className={classes.imgContainer}>
                              <img
                                className={classes.image}
                                src={article.fields.image.fields.file.url}
                                alt="article headline"
                                width="330"
                                height="157"
                              />
                            </Grid>
                            <Grid className={classes.textContainer}>
                              <TypoGraphy
                                variant="h4"
                                align="left"
                                className={classes.articleTitle}>
                                {article.fields.title}
                              </TypoGraphy>
                              <TypoGraphy
                                align="left"
                                className={classes.pubDateText}>
                                {formatDate(article.fields.pubDate)}
                              </TypoGraphy>
                            </Grid>
                          </Grid>
                          <Button
                            sx={{ paddingX: 8 }}
                            variant="contained"
                            color="primary">
                            More
                          </Button>
                        </Card>
                      </NextLink>
                    </Grid>
                  ))}
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </LatestArticleWrapper>
  );
};
