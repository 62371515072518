import type { Theme } from '@mui/material';
import type { GridSize, GridSpacing } from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Head from 'next/head';
import React from 'react';
import { Background } from '../Background';
import { Block } from '../Block';
import type { PageProps } from './Page.types';

const PREFIX = 'Page';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`& .${classes.root}`]: {
    color: theme.palette.primary.main,
    overflow: 'hidden',
    paddingTop: theme.spacing(20),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(10),
    },
  },
}));

export const Page: React.FC<PageProps> = ({ page }) => {
  const title = `Rumble Fish | ${page.fields.title}`;

  const meta = [
    { name: 'description', content: page.fields.description },
    { name: 'keywords', content: page.fields.category.join(', ') },
    { name: 'og:title', content: page.fields.title },
    { name: 'og:image', content: page.fields.facebookImage?.fields.file.url },
  ];

  return (
    <Root>
      <Head>
        <title>{title}</title>
        {meta.map((tag) => (
          <meta key={tag.name} {...tag} />
        ))}
      </Head>
      {page.fields.backgroundImage && (
        <Background image={page.fields.backgroundImage} />
      )}
      <div className={classes.root}>
        <Grid container justifyContent="center">
          {page.fields.outerColumnGapMd &&
            page.fields.outerColumnGapSm &&
            page.fields.outerColumnGapXs && (
              <>
                <Grid
                  item
                  xs={page.fields.outerColumnGapXs as GridSize}
                  sm={page.fields.outerColumnGapSm as GridSize}
                  md={page.fields.outerColumnGapMd as GridSize}
                />
                <Grid
                  item
                  xs={(12 - 2 * page.fields.outerColumnGapXs) as GridSize}
                  sm={(12 - 2 * page.fields.outerColumnGapSm) as GridSize}
                  md={(12 - 2 * page.fields.outerColumnGapMd) as GridSize}>
                  <Grid
                    container
                    spacing={page.fields.spacing as GridSpacing}
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}>
                    {page.fields?.blocks?.map((block) => (
                      <React.Fragment key={block.sys.id}>
                        <Block block={block} />
                      </React.Fragment>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={page.fields.outerColumnGapXs as GridSize}
                  sm={page.fields.outerColumnGapSm as GridSize}
                  md={page.fields.outerColumnGapMd as GridSize}
                />
              </>
            )}
        </Grid>
      </div>
    </Root>
  );
};

export default Page;
