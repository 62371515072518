import { Button, Grid } from '@mui/material';
import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import Headline from '../Headline';
import type { CurrentOpeningsProps } from './CurrentOpenings.types';
import OpeningItem from './OpeningItem';

const PREFIX = 'CurrentOpenings';

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  button: `${PREFIX}-button`,
  dropdown: `${PREFIX}-dropdown`,
};

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: '4rem 0',
    position: 'relative',
    paddingBottom: theme.spacing(5),
    '&::after': {
      content: "''",
      width: '120vw',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: '-20vw',
      background: 'rgba(1, 25, 111, 0.05)',
      zIndex: '-1',
    },
  },

  [`& .${classes.item}`]: {
    marginTop: theme.spacing(5),
  },

  [`& .${classes.button}`]: {
    textAlign: 'center',
  },

  [`& .${classes.dropdown}`]: {
    width: '100%',
    maxWidth: 410,
  },
}));

export const CurrentOpenings: React.FC<CurrentOpeningsProps> = ({ model }) => {
  const data = model.fields.jobPosting;

  const [isExpanded, setIsExpanded] = useState(false);
  const [isButtonHidden, setIsButtonHidden] = useState(false);

  const buttonLabel = useRef<string | null>(null);

  const filteredData = useMemo(() => {
    setIsButtonHidden(false);
    if (!isExpanded) {
      buttonLabel.current = 'Show More';
      return data.slice(0, 3);
    } else {
      buttonLabel.current = 'Show Less';
      return data;
    }
  }, [data, isExpanded]);

  return (
    <Root className={classes.root}>
      <Grid container>
        <Headline headline="Current Openings" />
        <Grid py={4} item xs={12}></Grid>
        <Grid item xs={12}>
          {filteredData.map((opening) => (
            <OpeningItem opening={opening} key={opening.sys.id} />
          ))}
        </Grid>
        <Grid item xs={12} className={clsx(classes.item, classes.button)}>
          {!isButtonHidden && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => setIsExpanded(!isExpanded)}>
              {buttonLabel.current}
            </Button>
          )}
        </Grid>
      </Grid>
    </Root>
  );
};
