import type { Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import React, { useEffect, useRef } from 'react';
import { colors } from '../../theme/default/color';
import { theme } from '../../theme/landingPage';
import { useDesktopDetect } from '../../theme/utils/useBreakpoints';
import { ContentfulImage } from '../ContentfulImage';
import type { LaptopSectionProps } from './LaptopSection.types';

const PREFIX = 'LaptopSection';

const classes = {
  root: `${PREFIX}-root`,
  laptopSectionHeading: `${PREFIX}-laptopSectionHeading`,
  laptopImg: `${PREFIX}-laptopImg`,
  laptopSectionImg: `${PREFIX}-laptopSectionImg`,
  laptopSectionChildren: `${PREFIX}-laptopSectionChildren`,
};

const Root = styled('section')(
  ({
    theme: {
      spacing,
      breakpoints,
      template: { normalSectionWidth },
    },
  }: {
    theme: Theme;
  }) => ({
    [`&.${classes.root}`]: {
      display: 'grid',
      backgroundColor: colors.footerGrey,
      gridTemplateColumns: `1fr repeat(2, minmax(0, calc(${normalSectionWidth} / 2))) 1fr`,
      gridTemplateRows: 'auto 14rem auto',
      gap: spacing(1),
      padding: spacing(10, 0, 10, 5),

      [breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
        padding: spacing(10, 0, 2, 2),
        gap: spacing(4),
        gridTemplateRows: 'auto',
      },
    },

    [`& .${classes.laptopSectionHeading}`]: {
      gridColumn: '2 / span 1',
      gridRow: '1 / span 1',

      [breakpoints.down('sm')]: {
        gridColumn: '1 / span 1',
        gridRow: '1 / span 1',
      },
    },

    [`& .${classes.laptopImg}`]: {
      position: 'absolute',
      [breakpoints.down('sm')]: {
        position: 'relative',
        bottom: '0 !important',
        height: '300px',
      },
    },

    [`& .${classes.laptopSectionImg}`]: {
      gridColumn: '3 / -1',
      gridRow: '1 / -1',

      marginLeft: '-4rem',
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end',

      [breakpoints.down('sm')]: {
        gridColumn: '1 / span 1',
        gridRow: '2 / span 1',
        marginLeft: '2rem',
        height: '300px',
      },
    },

    [`& .${classes.laptopSectionChildren}`]: {
      gridColumn: '2 / span 1',
      gridRow: '2 / -1',
      marginRight: '6rem',
      marginTop: '4rem',

      display: 'grid',
      gap: '3rem',

      [breakpoints.down('sm')]: {
        gridColumn: '1 / span 1',
        gridRow: '3 / span 1',
        marginRight: '0',
        marginTop: '0',
      },
    },
  })
);

export const LaptopSection: React.FC<LaptopSectionProps> = ({
  heading,
  name,
  laptopImage,
  children,
}) => {
  const imageRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const isOnDesktop = useDesktopDetect();

  const registerScrollTrigger = () => {
    if (typeof window !== 'undefined') {
      gsap.registerPlugin(ScrollTrigger);
    }
  };

  const disableScrollTrigger = () => {
    ScrollTrigger.getAll()[0]?.disable();
  };

  const mountParalaxOnDesktop = () => {
    if (isOnDesktop) {
      registerScrollTrigger();
      gsap.to(imageRef.current, {
        yPercent: 85,
        scrollTrigger: {
          trigger: containerRef.current,
          scrub: true,
          start: `${theme.spacing(10)} bottom`,
          end: 'bottom top',
        },
      });
    } else {
      disableScrollTrigger();
    }
  };

  useEffect(() => {
    mountParalaxOnDesktop();
  }, [isOnDesktop]);

  return (
    <Root id={name} className={classes.root}>
      <div className={classes.laptopSectionHeading}>{heading}</div>
      <div ref={containerRef} className={classes.laptopSectionImg}>
        <div ref={imageRef} className={classes.laptopImg}>
          <ContentfulImage
            image={laptopImage}
            width={isOnDesktop ? 800 : 300}
            height={isOnDesktop ? 644 : 244}
            src={laptopImage.fields.file.url}
          />
        </div>
      </div>
      <div className={classes.laptopSectionChildren}>{children}</div>
    </Root>
  );
};
